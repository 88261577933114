/* ==================================================
   Estilos Globais
   ================================================== */

/* Configuração de cor de fundo para todo o corpo da página */
body {
  background-color: #fcfcfc;
}

/* Centralização e formatação da aplicação */
.App {
  text-align: center;
}

/* Logo da aplicação */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Animação do logo em telas com movimento preferido */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Cabeçalho da aplicação */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Links da aplicação */
.App-link {
  color: #61dafb;
}

/* Animação de rotação para o logo da aplicação */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ==================================================
   Estilos do Banner
   ================================================== */

/* Configuração do container do banner */
.banner {
  margin-top: 0;
  padding: 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Estilo para a tagline dentro do banner */
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 8px;
  background: linear-gradient(
    90.21deg,
    rgba(40, 49, 118, 0.5) -5.91%,
    rgba(2, 134, 175, 0.5) 111.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

/* Título principal do banner */
.banner h1 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

/* Texto descritivo no banner */
.banner p {
  color: #313131;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

/* Botão no banner */
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 20px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

/* Configurações do SVG no botão */
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

/* Efeito de hover no botão */
.banner button:hover svg {
  margin-left: 25px;
}

/* Animação para imagens no banner */
.banner img {
  animation: updown 3s linear infinite;
}

/* Animação para movimento vertical */
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

/* ==================================================
   Configurações de Habilidades
   ================================================== */

.background-skills {
  background: linear-gradient(90.21deg, #0095cf -5.91%, #273377 111.58%);
  padding: 10px;
}

.skill h2 {
  @apply text-4xl font-bold;
  /* Utilizando Tailwind CSS */
}

.skill p {
  @apply text-gray-300 text-lg;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

/* Slider de habilidades */
.skill-slider {
  @apply w-4/5 mx-auto relative;
}

/* ==================================================
   Configurações de Scroll Customizado
   ================================================== */

/* Barra de rolagem personalizada */
.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #03a8db;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* ==================================================
   Outras Configurações
   ================================================== */

/* Badge do reCAPTCHA */
.grecaptcha-badge {
  z-index: 150;
}
